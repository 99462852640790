
video {
  --width: 45%;
  width: var(--width);
  height: calc(var(--width) * 0.75);
  margin: 0 0 20px 0;
  vertical-align: top;
}

video#localVideo {
  margin: 0 20px 20px 0;
}

div.box {
  margin: 1em;
}

/**************/

.cameras-div {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  //height: calc(100vh - 230px);
  justify-content: space-between;

  .camera-div {
    position: relative;
    width: 100%;
    /*margin-bottom: 20px;*/

    video {
      width: 100%;
      height: 95%;
    }

    .box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      opacity: 0.7;
      right: 0;

      div {
        background: #000000;
        text-align: center;
      }

      span {
        color: #FFFFFF;
        cursor: pointer;

        &:hover {
          color: #ED7246;
        }

        margin-right: 10px;
      }

      .control-btns2 {
        width: 100px;
        height: 100px;
        position: relative;
        border-radius: 50%;
        background: transparent;
        overflow: hidden;
        transform: rotate(-45deg);

        button {
          background: transparent;
          width: 50px;
          height: 50px;
          float: left;
          border: unset;

          span {
            transform: rotate(22.5deg);
            position: absolute;
            left: 0;
            top: 0;
            width: 50px;
            line-height: 50px;
            height: 50px;
          }
        }
      }
    }

    &:hover {
      .box {
        display: block;
      }
    }
  }
}

.control-btns1 {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 50%;
  background: #409eff;
  overflow: hidden;

  button {
    width: 60px;
    height: 60px;
    border: unset;
    position: absolute;

    span {
      transform: translate(-50%, -50%) rotate(-45deg);
      position: absolute;
      left: 50%;
      top: 50%;
    }

    &.left {
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    &.up {
      left: 50%;
      top: 0%;
      transform: translateX(-50%) rotate(45deg);
    }

    &.right {
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    &.down {
      left: 50%;
      bottom: 0%;
      transform: translateX(-50%) rotate(45deg);
    }
  }

}

.control-btns {
  width: 130px;
  height: 130px;
  position: relative;
  border-radius: 50%;
  background: #409eff;
  overflow: hidden;
  transform: rotate(-45deg);

  button {
    width: 65px;
    height: 65px;
    float: left;
    border: unset;

    span {
      transform: rotate(45deg);
      position: absolute;
      left: 0;
      top: 0;
      width: 65px;
      line-height: 65px;
      height: 65px;
    }
  }
}

.title-menu {
  font-size: 16px;
  letter-spacing: 2px;
}

.count-span {
  display: block;
  position: absolute;
  top: 50%;
  left: 200px;
  transform: translateY(-50%);
  font-size: 12px;

  span {
    color: #f1543f;
  }
}

.video-title {
  position: absolute;
  color: #ffffff;
  right: 5px;
  font-size: 16px;
  z-index: 1;
}
